<template>
    <div class="card box widget-social pt-2 pb-2" :class="[alarmStatus,componentStyle]">
        <widgetTitleBar :moduleData="this.moduleData" :widgetInfo="content" :alarmList="this.alarmList" :menu-items="this.items" @menu-click="($value) => this.toggle($value)"></widgetTitleBar>
        <OverlayPanel
            class="param-overlay"
            ref="alarmOverlay"
            appendTo="body"
            :autoZIndex="true"
            @show="focusElement" 
            :dismissable="true"
            :showCloseIcon="true"
            :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
            :style="{ height: 'auto' }"
        >
            <div v-for="(element) in this.alarmList" :key="element.label">
                <span>🔴 {{ element.label }}</span>
            </div>
        </OverlayPanel>
        <div v-if="isLoading">
            <loader-skeleton :widgetInfo="content"></loader-skeleton>
        </div>
        <OverlayPanel class="param-overlay" ref="periods" appendTo="body" :autoZIndex="true" @show="focusElement" 
            :dismissable="false" :showCloseIcon="true" :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
            :style="{ height: '300px' }">
        </OverlayPanel>
        <Dialog 
            v-model:visible="showDiagPeriods"
            @show="focusDiag"
            @hide="hideOP"
            :breakpoints="{ '960px': '85vw', '640px': '90vw' }"
            :style="{ width: '40vw' }"
            class="p-fluid"
            >
            <template #header>
                <h5><i class="pi pi-cog">&nbsp;</i>
                    <span class="subtext mt-1">
                        {{ moduleDepartment.key }} - {{ moduleDepartment.label }} ⚡ Befeuchtungs-Perioden Einstellungen
                    </span>
                </h5>
            </template>
            <div v-if="saveWait" class="flex justify-content-center flex-wrap">
                <ProgressSpinner
                style="width: 50px; height: 50px"
                strokeWidth="4"
                fill="var(--red-600)"
                animationDuration="1s"
                />
            </div>
            <div v-else @keydown.esc="hideOP" @keydown.enter="saveDiag('periods')">
                <loader v-if="dialogLoading" :style="'box'"></loader>
                <TabView v-else :scrollable="true" :activeIndex="diagPeriod">
                    <TabPanel v-for="(element, index) in this.nodeDataPeriods" :key="index">
                        <!-- <Panel :header="returnParameter(element, 'parameter', 'PeriodName').value + ' - Sollwert'"> -->
                        <template #header>
                            <div class="flex align-items-center gap-2">
                                <span class="white-space-nowrap" :class="this.getDiagDataPeriods(index, 'periodEnable').value ? '' : 'line-through'">{{ index+1 }}: {{ this.getDiagDataPeriods(index, 'periodName').value }}</span>
                            </div>
                        </template>
                        <Panel :header="`${this.getDiagDataPeriods(index, 'periodName').value} - Periode aktivieren`">
                            <template #icons>
                                <i class="fi fi-rr-temperature-low bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <div class="field col flex justify-content-center">
                                        <div class="flex align-items-center">
                                            <InputSwitch v-model="this.getDiagDataPeriods(index, 'periodEnable').value" /><span class="ml-2">Periode aktivieren</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                        <Panel :header="`${this.getDiagDataPeriods(index, 'periodName').value} - Sollwert relative Feuchte`" class="mt-2" v-if="this.getDiagDataPeriods(index, 'periodEnable').value">
                            <template #icons>
                                <i class="fi fi-rr-temperature-low bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col mr-2">
                                    <div class="p-inputgroup">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="setpoint" ref="setpoint" :min="0" :max="100"
                                                v-model="this.getDiagDataPeriods(index, 'setpoint').value" autofocus @focus="focusElement"
                                                :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                                inputClass="nice-font inputtext-param text-center text-5xl"  />
                                                <label for="setpoint" class="mr-2">{{ this.getDiagDataPeriods(index, 'setpoint').label }}</label>
                                            <span class="p-inputgroup-addon nice-font text-3xl">{{ this.getDiagDataPeriods(index, 'setpoint').unit }}</span>
                                        </span>
                                    </div>
                                </div>
                                <div class="field col">
                                    <div class="field col flex justify-content-center">
                                        <div class="flex align-items-center">
                                            <InputSwitch v-model="this.getDiagDataPeriods(index, 'useRhRel').value" /><span class="ml-2">{{ this.getDiagDataPeriods(index, 'useRhRel').label }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                        <Panel :header="`${this.getDiagDataPeriods(index, 'periodName').value} - Sollwert Feuchtedefizit`" class="mt-2" v-if="this.getDiagDataPeriods(index, 'periodEnable').value">
                            <template #icons>
                                <i class="fi fi-rr-temperature-low bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col mr-2">
                                    <div class="p-inputgroup">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="setpointRhDef" ref="setpointRhDef" :min="0" :max="50"
                                                v-model="this.getDiagDataPeriods(index, 'setpointRhDef').value" autofocus @focus="focusElement"
                                                :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                                inputClass="nice-font inputtext-param text-center text-5xl"  />
                                                <label for="setpointRhDef" class="mr-2">{{ this.getDiagDataPeriods(index, 'setpointRhDef').label }}</label>
                                            <span class="p-inputgroup-addon nice-font text-3xl">{{ this.getDiagDataPeriods(index, 'setpointRhDef').unit }}</span>
                                        </span>
                                    </div>
                                </div>
                                <div class="field col">
                                    <div class="field col flex justify-content-center">
                                        <div class="flex align-items-center">
                                            <InputSwitch v-model="this.getDiagDataPeriods(index, 'useRhDef').value" /><span class="ml-2">{{ this.getDiagDataPeriods(index, 'useRhDef').label }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                        <Panel :header="`${this.getDiagDataPeriods(index, 'periodName').value} - Zeiteinstellungen`" class="mt-2" v-if="this.getDiagDataPeriods(index, 'periodEnable').value">
                            <template #icons>
                                <i class="fi fi-rr-time-fast bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <div class="flex justify-content-center mb-2">
                                        <SelectButton v-model="this.getDiagDataPeriods(index, 'TimeModeStart').value" :options="this.$store.getters.getTimeType" optionLabel="name" optionValue="value" dataKey="value" aria-labelledby="name">
                                            <template #option="slotProps">
                                                <span class="flex justify-content-center mx-auto" v-tooltip="slotProps.option.name"><i :class="slotProps.option.icon" class="text-xl icon-inline"></i></span>
                                            </template>
                                        </SelectButton>
                                    </div>
                                    <div class="field">
                                        <div class="p-inputgroup">
                                            <span v-if="this.getDiagDataPeriods(index, 'TimeModeStart').value !== 0" class="p-inputgroup-addon">
                                                <ToggleButton v-model="this.getDiagDataPeriods(index, 'TimeSetStart').plusminus" onIcon="fi fi-rr-minus" offIcon="fi fi-rr-plus" />
                                            </span>
                                            <span class="p-float-label mr-2">
                                                <InputMask id="minTimeStart" ref="minValueTimeStart" mask="99:99:99"
                                                    placeholder="" slotChar="hh:mm:ss"
                                                    v-model="this.getDiagDataPeriods(index, 'TimeSetStart').value"
                                                    @focus="focusElement" class="p-inputtext-lg w-full" />
                                                <label for="minValueTimeStart" class="mr-2">Periode Beginn</label>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="field col">
                                    <div class="flex justify-content-center mb-2">
                                        <SelectButton v-model="this.getDiagDataPeriods(index, 'TimeModeEnd').value" :options="this.$store.getters.getTimeType" optionLabel="name" optionValue="value" dataKey="value" aria-labelledby="name">
                                            <template #option="slotProps">
                                                <span class="flex justify-content-center mx-auto" v-tooltip="slotProps.option.name"><i :class="slotProps.option.icon" class="text-xl icon-inline"></i></span>
                                            </template>
                                        </SelectButton>
                                    </div>
                                    <div class="field">
                                        <div class="p-inputgroup">
                                            <span v-if="this.getDiagDataPeriods(index, 'TimeModeEnd').value !== 0" class="p-inputgroup-addon">
                                                <ToggleButton v-model="this.getDiagDataPeriods(index, 'TimeSetEnd').plusminus" onIcon="fi fi-rr-minus" offIcon="fi fi-rr-plus"/>
                                            </span>
                                            <span class="p-float-label">
                                                <InputMask id="minTimeEnd" ref="minValueTimeEnd" mask="99:99:99" placeholder=""
                                                    slotChar="hh:mm:ss" v-model="this.getDiagDataPeriods(index, 'TimeSetEnd').value"
                                                    @focus="focusElement" class="p-inputtext-lg w-full" />
                                                <label for="minValueTimeEnd">Periode Ende</label>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                        <Panel :header="`${this.getDiagDataPeriods(index, 'periodName').value} - Intervall-Einstellungen`" class="mt-2" v-if="this.getDiagDataPeriods(index, 'periodEnable').value">
                            <template #icons>
                                <i class="fi fi-rr-time-fast bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid">
                                <div class="field col-6">
                                    <div class="field">
                                        <div class="p-inputgroup">
                                            <span class="p-float-label">
                                                <InputMask id="sprayTime" ref="sprayTime" mask="99:99:99" placeholder=""
                                                    slotChar="hh:mm:ss" v-model="this.getDiagDataPeriods(index, 'sprayTime').value"
                                                    @focus="focusElement" class="p-inputtext-lg w-full" />
                                                <label for="sprayTime" class="mr-2">{{ this.getDiagDataPeriods(index, 'sprayTime').label }}</label>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="field col-6">
                                    <div class="field">
                                        <div class="p-inputgroup">
                                            <span class="p-float-label">
                                                <InputMask id="pauseTime" ref="pauseTime" mask="99:99:99" placeholder=""
                                                    slotChar="hh:mm:ss" v-model="this.getDiagDataPeriods(index, 'pauseTime').value"
                                                    @focus="focusElement" class="p-inputtext-lg w-full" />
                                                <label for="pauseTime" class="mr-2">{{ this.getDiagDataPeriods(index, 'pauseTime').label }}</label>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                        <div class="formgrid grid p-1">
                            <div class="field col">
                                <div class="flex justify-content-center mt-2">
                                    <Button label="Periodenbezeichnung ändern" @click="toogleNameDiag(this.getDiagDataPeriods(index, 'periodName'))" />
                                </div>
                            </div>
                        </div>
                        
                    </TabPanel>
                </TabView>
            </div>
            <template #footer>
                <widgetDialogFooter :widgetName="`${this.$options.name}_periods`" :department="moduleDepartment.key" :exchange="true" format="json" :saveCheck="periodCheck"
                    @diagAbort="diagTogglePeriods"
                    @diagSave="saveDiag('periods')"
                    @import="($value) => {this.nodeDataPeriods = $value}"
                    :exportObject="this.nodeDataPeriods"
                ></widgetDialogFooter>
            </template>
        </Dialog>
        <OverlayPanel ref="misc" appendTo="body" @show="focusElement" :dismissable="false">
        </OverlayPanel>
        <Dialog
            v-model:visible="showDiagMisc"
            @show="focusElement"
            :breakpoints="{ '960px': '85vw', '640px': '90vw' }"
            :style="{ width: '40vw' }"
        >
            <template #header>
                <h5><i class="pi pi-cog">&nbsp;</i><span class="subtext mt-1">{{ moduleDepartment.key }} {{ moduleDepartment.label }} ⚡ Allgemeine Einstellungen</span></h5>
            </template>
            <div v-if="saveWait" class="flex justify-content-center flex-wrap">
                <ProgressSpinner
                style="width: 50px; height: 50px"
                strokeWidth="4"
                fill="var(--red-600)"
                animationDuration="1s"
                />
            </div>
            <div v-else @keydown.esc="hideOP" @keydown.enter="saveDiag('misc')">
                <loader v-if="dialogLoading" :style="'box'"></loader>
                <TabView :scrollable="true">
                    <TabPanel header="Programmeinstellungen">
                        <Panel header="Befeuchtung Hauptschalter">
                            <template #icons>
                                <i class="fi fi-rr-interrogation bg-bespin fg-lightYellow p-panel-header-icon text-xl mr-1" v-tooltip="`<span class='fg-lightYellow'>Hilfe</span><hr class='mt-1 mb-1' />Der Hauptschalter gibt unabhängig von den Perioden die Befeuchtung übergeordnet frei oder sperrt sie.`"></i>
                                <i class="fi fi-rr-temperature-low bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <div class="field col flex justify-content-center">
                                        <div class="flex align-items-center">
                                            <InputSwitch v-model="this.getDiagDataCfg('enableSpraying').value" /><span class="ml-2">Sprühnebel aktivieren</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="Lichtführung" class="mt-2">
                            <template #icons>
                                <i class="fi fi-rr-temperature-low bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="lightAdaptionValue" ref="lightAdaptionValue" :min="-50" :max="100"
                                            v-model="getDiagDataCfg('lightAdaptionValue').value" mode="decimal"
                                            :suffix="` ${getDiagDataCfg('lightAdaptionValue').unit}`"
                                            :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="lightAdaptionValue" class="mr-2">{{ getDiagDataCfg('lightAdaptionValue').label }}</label>
                                    </span>
                                </div>
                                <div class="field col">
                                    <div class="field col flex justify-content-center">
                                        <div class="flex align-items-center">
                                            <InputSwitch v-model="this.getDiagDataCfg('lightAdaptionEnable').value" /><span class="ml-2">{{ this.getDiagDataCfg('lightAdaptionEnable').label }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="lightAdaptionStart" ref="lightAdaptionStart" :min="-50" :max="100"
                                            v-model="getDiagDataCfg('lightAdaptionStart').value" mode="decimal"
                                            :suffix="` ${getDiagDataCfg('lightAdaptionStart').unit}`"
                                            :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="lightAdaptionStart" class="mr-2">{{ getDiagDataCfg('lightAdaptionStart').label }}</label>
                                    </span>
                                </div>
                                <div class="field col">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="lightAdaptionEnd" ref="lightAdaptionEnd" :min="-50" :max="100"
                                            v-model="getDiagDataCfg('lightAdaptionEnd').value" mode="decimal"
                                            :suffix="` ${getDiagDataCfg('lightAdaptionEnd').unit}`"
                                            :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="lightAdaptionEnd" class="mr-2">{{ getDiagDataCfg('lightAdaptionEnd').label }}</label>
                                    </span>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="Verzögerungen" class="mt-2">
                            <template #icons>
                                <i class="fi fi-rr-clock bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <span class="p-float-label mr-2">
                                        <InputMask id="onDelay" ref="onDelay" mask="99:99:99"
                                            placeholder="" slotChar="hh:mm:ss"
                                            v-model="this.getDiagDataCfg('onDelay').value"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="onDelay" class="mr-2">{{ getDiagDataCfg('onDelay').label }}</label>
                                    </span>
                                </div>
                            </div>
                        </Panel>
                    </TabPanel>
                    <TabPanel header="Service-Parameter" v-if="isMaster">
                        <Panel header="Regler-Einstellungen" class="mt-2">
                            <template #icons>
                                <i class="fi fi-rr-heat bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid">
                                <div class="field col-4">
                                    <div class="p-inputgroup">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="zeroPoint" ref="zeroPoint" :min="0" :max="100"
                                                v-model="this.getDiagDataCfg('zeroPoint').value" autofocus @focus="focusElement"
                                                :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                                inputClass="nice-font inputtext-param text-center text-xl"  />
                                                <label for="zeroPoint" class="mr-2">{{ this.getDiagDataCfg('zeroPoint').label }}</label>
                                            <span class="p-inputgroup-addon nice-font text-md">{{ this.getDiagDataCfg('zeroPoint').unit }}</span>
                                        </span>
                                    </div>
                                </div>
                                <div class="field col-4">
                                    <div class="p-inputgroup">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="zeroPointRhDef" ref="zeroPointRhDef" :min="0" :max="100"
                                                v-model="this.getDiagDataCfg('zeroPointRhDef').value" autofocus @focus="focusElement"
                                                :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                                inputClass="nice-font inputtext-param text-center text-xl"  />
                                                <label for="zeroPointRhDef" class="mr-2">{{ this.getDiagDataCfg('zeroPointRhDef').label }}</label>
                                            <span class="p-inputgroup-addon nice-font text-md">{{ this.getDiagDataCfg('zeroPointRhDef').unit }}</span>
                                        </span>
                                    </div>
                                </div>
                                <div class="field col-4">
                                    <div class="p-inputgroup">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="sensorFilterTime" ref="sensorFilterTime" :min="0" :max="1000"
                                                v-model="this.getDiagDataCfg('sensorFilterTime').value" autofocus @focus="focusElement"
                                                :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                                inputClass="nice-font inputtext-param text-center text-xl"  />
                                                <label for="sensorFilterTime" class="mr-2">{{ this.getDiagDataCfg('sensorFilterTime').label }}</label>
                                            <span class="p-inputgroup-addon nice-font text-md">{{ this.getDiagDataCfg('sensorFilterTime').unit }}</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                    </TabPanel>
                </TabView>
            </div>
            <template #footer>
                <widgetDialogFooter :widgetName="`${this.$options.name}_misc`" :department="moduleDepartment.key" :exchange="true" format="json"
                    @diagAbort="diagToggleMisc"
                    @diagSave="saveDiag('misc')"
                    @import="($value) => {this.nodeDataCfg = $value}"
                    :exportObject="this.nodeDataCfg"
                ></widgetDialogFooter>
            </template>
        </Dialog>

        <div v-if="!isLoading">
            <div class="flex justify-content-between align-items-center p-0 flex-wrap">
                <div class="flex justify-content-start align-items-center p-0">
                    <moduleIconEnat :icon="moduleData.metadata.icon" :alarmStatus="alarmStatus" :alarmQueue="alarmQueue" @toogleAlarmOverlay="toogleAlarmOverlay"></moduleIconEnat>
                    <div class="hidden sm:flex mt-1 ml-2">
                        <widget-mini-binary-pulse label="Ventil" :value="this.getModuleValue('OUT_SprayValve')" icon="fi-rr-raindrops"></widget-mini-binary-pulse>
                    </div>
                    <div class="flex flex-column mt-3">

                    </div>
                </div>
                <div class="flex justify-content-end align-items-center p-0">
                    <widget-header :value="this.getModuleValue('IN_RH')" :moduleData="this.moduleData" @showChart="showChart('value1')" :fgColor="fontColor" />
                </div>
            </div>

            <div class="stats flex mt-1">
                <!-- <div class="left flex flex-column"> -->
                <div class="left">
                    <ul class="widget-list">
                        <Widget_rowValues2 :value1="this.getModuleValue('OUT_SprayValve')" :value2="this.getModuleValue('OUT_SprayActive')"></Widget_rowValues2>
                        <Widget_rowValues2 :value1="this.getModuleValue('OUT_SprayInterval')" :value2="this.getModuleValue('OUT_LightAdaption')"></Widget_rowValues2>
                        <Widget_rowValues2 :value1="this.getModuleValue('OUT_AktPeriode.useRhRel')" :value2="this.getModuleValue('OUT_AktPeriode.setpoint')"></Widget_rowValues2>
                        <Widget_rowValues2 :value1="this.getModuleValue('OUT_AktPeriode.useRhDef')" :value2="this.getModuleValue('OUT_AktPeriode.setpointRhDef')"></Widget_rowValues2>
                    </ul>
                </div>
                <div class="right flex flex-column">
                    <div class="flex flex-wrap">
                        <div class="flex-1 align-items-center justify-content-center mt-3">
                            <button-enat title="Perioden" subtitle="Einstellung Perioden Luftbefeuchtung" icon="fi fi-rr-time-twenty-four" fgColor="yellow" bgColor="teal"
                                :badge="periodIndex + 1" @click="toggleOP($event, 'periods')" >
                            </button-enat>
                        </div>
                        <div class="flex-1 align-items-center justify-content-center mt-3">
                            <button-enat title="Allgemein" subtitle="Allgemeine Einstellungen" icon="fi fi-rr-settings" fgColor="yellow" bgColor="darkSteel"
                                @click="toggleOP($event, 'misc')" >
                            </button-enat>
                        </div>
                    </div>
                    <div class="flex flex-wrap mt-2">
                        <div class="flex-1 align-items-center justify-content-center mr-1 mt-1">
                            <valuebox-enat title="RH-Rel.Soll" :value="getModuleValue('OUT_AktPeriode.setpoint').value + getModuleValue('OUT_AktPeriode.setpoint').unit" :moduleData="moduleData" icon="fi fi-rr-humidity" />
                        </div>
                        <div class="flex-1 align-items-center justify-content-center mr-1 mt-1">
                            <valuebox-enat title="RH-Def.Soll" :value="getModuleValue('OUT_AktPeriode.setpointRhDef').value + getModuleValue('OUT_AktPeriode.setpointRhDef').unit" :moduleData="moduleData" icon="fi fi-rr-humidity" />
                        </div>
                    </div>
                    <div class="flex flex-wrap mt-2">
                        <div class="flex-1 align-items-center justify-content-center mr-1 mt-1">
                            <valuebox-enat title="Periode" :value="getModuleValue('OUT_AktPeriode.periodName').value" :moduleData="moduleData" icon="fi fi-rr-time-twenty-four" />
                        </div>
                    </div>
                </div>
            </div>
            <textbox-enat title="Status" :value="currentStatusStr" :moduleData="moduleData" icon="fi fi-rr-comment-alt" />
        </div>
        <Dialog 
            v-model:visible="showDiagName"
            :closable="false"
            :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
            :style="{ width: '450px' }"
            :modal="true"
            class="p-fluid"
        >
            <template #header>
                <p class="nice-font"><i class="fi fi-rr-text"></i><span class="pl-1 mt-1">Bezeichnung ändern</span></p>
            </template>
            <div @keydown.esc="hideNameDiag" @keydown.enter="saveNameDiag(changeDiagName)">
                <Panel>
                    <div class="grid p-fluid">
                        <div class="col-12 pl-5 pr-5">
                            <span class="p-float-label mr-2">
                                <InputText id="periodName" ref="periodName" v-model="changeDiagName.value" locale="de-DE" class="nice-font inputtext-param text-center text-2xl" />
                                <label for="periodName" class="mr-2">Bezeichnung</label>
                            </span>
                        </div>
                    </div>
                </Panel>
            </div>
            <template #footer>
            <Button label="Abbrechen" icon="pi pi-times" class="p-button-text" @click="hideNameDiag" />
            <Button label="Speichern" icon="pi pi-check" class="p-button-text" @click="saveNameDiag(changeDiagName)" />
            </template>
        </Dialog>
        <div v-if="entryDialog === true">
            <dialog-io-single v-bind:showDialog="entryDialog" :dialogObj="ioObj" v-bind:dialogData="ioDialogData"
                @entrySave="
                    ($value) => {
                        changeVal($value);
                    }
                " @entryAbort="hideDialog()"></dialog-io-single>
        </div>
        <div v-if="entryDialog === true">
            <dialog-io-single v-bind:showDialog="entryDialog" :dialogObj="ioObj" v-bind:dialogData="ioDialogData"
                @entrySave="
                    ($value) => {
                        changeVal($value);
                    }
                " @entryAbort="hideDialog()"></dialog-io-single>
        </div>
        <dialog-chart-single :icon="getModuleValue('IN_RH').icon" :node="getModuleValue('IN_RH')" v-bind:show="showChart1" @chart-close="showChart1 = false"></dialog-chart-single>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import store from '@/store';
import { mapGetters, mapActions } from 'vuex';
import helpers from '@/helpers';
import { parseNode, setOpcValueToast } from '@/helpers';
import SocketioService from '@/services/socketioService.js';
import Widget_rowValues2 from '@/components/fragments/widget_rowValues2.vue';
import dialogChartSingle from '@/components/dialog/chartSingle.vue';
import widgetMiniBinaryPulse from '@/components/fragments/widgetMiniBinaryPulse.vue';
// import widgetAlarmOutput from '@/components/fragments/widgetAlarmOutput.vue';
import widgetDialogFooter from '@/components/fragments/widgetDialogFooter.vue';
import moduleIconEnat from '@/components/fragments/moduleIconEnat.vue';
import widgetTitleBar from '@/components/fragments/widgetTitleBar.vue';
// import widgetTimeSet from '@/components/fragments/widgetTimeSet.vue';

export default defineComponent({
    name: 'widgetSprayingController',
    setup() { },
    components: {
        Widget_rowValues2,
        dialogChartSingle,
        widgetMiniBinaryPulse,
        widgetDialogFooter,
        moduleIconEnat,
        widgetTitleBar,
    },
    props: {
        content: {
            type: null,
            required: true,
        },
        dragObj: {
            type: null,
            required: false,
        },
        valueObj: {
            type: null,
            required: false,
        },
        dataObj: {
            type: null,
            required: false,
        },
        styleObj: {
            type: null,
            required: false,
        },
        createdLayout: {
            type: Boolean,
            required: false,
        },
        readyLayout: {
            type: Boolean,
            required: false,
        },
    },
    watch: {
        createdLayout() {
            this.watchCreatedLayout();
        },
        readyLayout() {
            this.watchLayout();
        },
        '$store.getters.getPageData.editMode': function () {
            this.checkEditMode();
        },
        // nodeEntry: {
        //     handler: function(entry) {
        //         // this.filterParameters();
        //         // if (Array.isArray(entries) && entries.length > 0) {
        //         //     this.loading = false;
        //         // } else {
        //         //     this.loading = true;
        //         // }
        //     },
        //     deep: true
        // }
    },
    computed: {
        ...mapGetters({
            editMode: 'getEditMode',
            getNodeWidget: 'getNodeWidget',
            getOneDepartment: 'types/getOneDepartment',
            getOneCategory: 'types/getOneCategory',
            getIoTree: 'getIoTree',
            getStyleColors: 'getStyleColors',
            getStylePatterns: 'getStylePatterns',
            getModules: 'opcua/getModules',
            getModule: 'opcua/getModule',
            getNode: 'opcua/getNode',
            getModuleFetched: 'opcua/getModuleFetched',
            isMaster: 'auth/isMaster',
        }),
        isLoading: function() {
            if (!this.moduleData || !this.getModuleFetched(this.moduleHash) || this.loading) return true;
            return false;
        },
        
        connectedModule: function () {
            if (this.content.data.module) {
                return this.content.data.module;
            }
            return null;
        },
        moduleData: function () {
            if (this.connectedModule) {
                const module = this.getModule(this.connectedModule);
                if (module) return module;
            }
            return null;
        },
        moduleHash: function () {
            if (this.moduleData !== null) {
                if (this.moduleData.source.server !== null && this.moduleData.source.identifier !== null) {
                    return `${this.moduleData.source.server}@${this.moduleData.source.identifier}`
                }
            }
            return null;
        },
        moduleDepartment: function () {
            if (this.moduleData !== null) {
                if (this.moduleData.metadata.department !== null) {
                    return this.getOneDepartment(this.moduleData.metadata.department);
                }
            }
            return { key: store.getters.getNoval, label: store.getters.getNoval };
        },
        moduleCategory: function () {
            if (this.moduleData !== null) {
                if (this.moduleData.metadata.category !== null) {
                    return this.getOneDepartment(this.moduleData.metadata.category);
                }
            }
            return { key: store.getters.getNoval, label: store.getters.getNoval };
        },
        componentName: function () {
            if (this.content.name !== undefined) {
                return this.content.name;
            } else {
                return '???';
            }
        },
        componentLabel: function () {
            if (this.content.label !== undefined) {
                return this.content.label;
            } else if (this.moduleData !== null && this.moduleData.metadata.label) {
                return this.moduleData.metadata.label;
            } else {
                return store.getters.getNoval;
            }
        },
        componentContent: function () {
            if (this.content.content !== undefined) {
                return this.content.content;
            } else {
                return '???';
            }
        },
        componentStyle: function () {
            if (this.content.style && this.content.style !== 'standard') {
                return 'box ptrn-' +  this.content.style.pattern + ' bg-' + this.content.style.background + ' fg-' + this.content.style.foreground;
            } else {
                return '';
            }
        },
        fontColor: function () {
            if (this.content.style && this.content.style.foreground) {
                return `fg-${this.content.style.foreground}`;
            }
            return 'gray';
        },
        alarmStatus: function () {
            let alarmCount = this.alarmEntriesCount;
            for (let i=0; i<this.alarmEntriesCount; i++) {
                // console.log(this.getModuleValue(`AlarmEntries[${i}].OUT_AlarmState`));
                if (this.getModuleValue(`AlarmEntries[${i}].OUT_AlarmState`).value <= 0) {
                    alarmCount -= 1;
                }
            }
            alarmCount = 0;
            return alarmCount;
        },
        alarmQueue: function () {
            let alarmCount = this.alarmEntriesCount;
            for (let i=0; i<this.alarmEntriesCount; i++) {
                // console.log(this.getModuleValue(`AlarmEntries[${i}].OUT_AlarmState`));
                if (this.getModuleValue(`AlarmEntries[${i}].OUT_AlarmState`).value === 0) {
                    alarmCount -= 1;
                }
            }
            return alarmCount;
        },
        alarmList: function () {
            let activeAlarms = [];
            for (let i=0; i<this.alarmEntriesCount; i++) {
                if (this.getModuleValue(`AlarmEntries[${i}].OUT_AlarmState`).value !== 0) {
                    const alarm = {
                        label: this.getModuleValue(`AlarmEntries[${i}].CFG.alarmLabel`).value,
                        state: this.getModuleValue(`AlarmEntries[${i}].OUT_AlarmState`).value
                    }
                    activeAlarms.push(alarm);
                }
            }
            return activeAlarms;
        },
        alarmTicker: function () {
            let alarmStr = '';
            for (let i=0; i<this.alarmEntriesCount; i++) {
                if (this.getModuleValue(`AlarmEntries[${i}].OUT_AlarmState`).value > 0) {
                    alarmStr += `🔴 ${this.getModuleValue(`AlarmEntries[${i}].CFG.alarmLabel`).value} `;
                }
            }
            return alarmStr;
        },
        orientationStr1: function () {
            return helpers.ventOrientation(this.getModuleValue('CFG.ausrichtung').value, 1);
        },
        orientationStr2: function () {
            return helpers.ventOrientation(this.getModuleValue('CFG.ausrichtung').value, 2);
        },
        currentStatusStr: function () {
            return this.getModuleValue('OUT_StatusStr').value;
        },
        currentPositionFloat1: function () {
            return parseFloat(this.getModuleValue('OUT_Stellung_1_Aktuell').value);
        },
        currentPositionFloat2: function () {
            return parseFloat(this.getModuleValue('OUT_Stellung_2_Aktuell').value);
        },
        icon: function () {
            if (this.alarmStatus === 0) {
                return this.moduleData.metadata.icon
            } else {
                return `${this.moduleData.metadata.icon} fg-red pulse-loop cursor-pointer bg-lightCrimson`;
            }
        },
        periodIndex: function () {
            return this.getModuleValue('OUT_AktPeriode.periodIndex').value;
        },
        periodCheck: function () {
            if (this.nodeDataPeriods.length > 0) {
                let cnt = 0;
                this.nodeDataPeriods.forEach((period) => {
                    const result = period.find(entry => entry.displayName === 'periodEnable');
                    if (result) {
                        if (result.value === true) cnt+=1;
                    }
                });
                if (cnt > 0) return false;
            }
            return true;
        },
    },
    data() {
        return {
            loading: false,
            showChart1: false,
            saveWait: false,
            dialogLoading: false,
            entryDialog: false,
            valveDialog: false,
            ioDialogData: {},
            ioObj: {},
            timeSetGrp: null,
            nodeData: [],
            nodeDataOld: [],
            nodeDataCfg: [],
            nodeDataCfgOld: [],
            nodeDataPeriods: [],
            nodeDataPeriodsOld: [],
            diagPeriod: 0,
            showDiagName: false,
            currentGrp: {
                name: null,
                label: null,
                enable: null,
                timeSet: null,
                limits: {},
                controller: {},
            },
            node: null,
            lala: null,
            showDiagPeriods: false,
            showDiagMisc: false,
            submitted: false,
            selectedKey: {},
            expandedKeys: {},
            items: [],
            items1: [
                {
                    label: 'Perioden',
                    icon: 'fi fi-rr-settings',
                    command: () => {
                        // this.editEntry(this.content);
                        this.toggleOP(null, 'periods');
                    },
                },
                {
                    label: 'Einstellungen',
                    icon: 'fi fi-rr-settings',
                    command: () => {
                        // this.editValve(this.content);
                        this.toggleOP(null, 'misc');
                    },
                },
            ],
            items2: [
                {
                    separator: true,
                },
                {
                    label: 'Widget Config',
                    icon: 'pi pi-cog',
                    command: () => {
                        this.editEntry(this.content);
                    },
                },
                {
                    label: 'Duplizieren',
                    icon: 'pi pi-clone',
                    command: () => {
                        this.duplicateEntry();
                    },
                },
                {
                    label: 'Löschen',
                    icon: 'pi pi-times',
                    command: () => {
                        this.deleteEntry();
                    },
                },
            ],
        };
    },
    created() {
        this.loading = true;
    },
    mounted() {
        this.checkEditMode();
        if (!this.getModuleFetched(this.moduleHash)) {
            this.loadModule(this.connectedModule).then((module) => {
                const moduleHash = `${module.source.server}@${module.source.identifier}.*`;
                SocketioService.getRedisNodesParam(moduleHash, (err, response) => {
                    if (!err && response) {
                        if (Array.isArray(response) && response.length > 0) {
                            this.setNodesFull(response);
                        }
                    }
                }).then(() => { 
                    if (this.editMode) console.log(`[ENATWIDGET] ${this.moduleHash} : component ready!`);
                    this.setModuleFetched(this.moduleHash);
                    this.loading = false;
                });
            });
        } else {
            this.loading = false;
        }
    },
    methods: {
        ...mapActions({
            loadNode: 'opcua/loadNode',
            loadModule: 'opcua/loadModule',
            setNodesFull: 'opcua/setNodesFull',
            setModuleFetched: 'opcua/setModuleFetched',
        }),
        loadingState(state) {
            if (state) {
                this.loading = true;
            } else {
                this.loading = false;
            }
        },
        getModuleValue(schemeEntry) {
            const key = `${this.moduleHash}.${schemeEntry}`;
            return this.getNode(key);
        },
        getModuleValueStr(schemeEntry) {
            const key = `${this.moduleHash}.${schemeEntry}`;
            const node = this.getNode(key);
            if (typeof node.unit === 'string' && node.unit.length > 0) {
                return `${node.value}${node.unit}`;
            }
            return `${node.value}`;
        },
        getDiagData(key) {
            if (this.nodeData.length > 0) {
                const result = this.nodeData.find(entry => entry.key2 === key);
                return result;
            }
            return null;
        },
        getDiagDataPeriods(index, displayName) {
            if (this.nodeDataPeriods.length > 0 && index >= 0) {
                const result = this.nodeDataPeriods[index].find(entry => entry.displayName === displayName);
                return result;
            }
            return null;
        },
        getDiagDataCfg(displayName) {
            if (this.nodeDataCfg.length > 0 && displayName) {
                const result = this.nodeDataCfg.find(entry => entry.displayName === displayName);
                return result;
            }
            return null;
        },
        watchCreatedLayout() {
            // this.loading = true;
        },
        watchLayout() {
        },
        checkEditMode() {
            if (this.$store.getters.getPageData.editMode) {
                this.items = [...this.items1, ...this.items2];
            } else {
                this.items = [...this.items1];
            }
        },
        clearVals() {
            this.nodeDataCfg = [];
            this.nodeDataCfgOld = [];
            this.nodeDataPeriods = [];
            this.nodeDataPeriodsOld = [];
            this.saveWait = false;
            this.dialogLoading = false;
            this.diagPeriod = 0;
        },
        updateMap() {
            // this.node = helpers.mapNodes(_.cloneDeep(this.nodeEntry.children), null);
            if (this.node && this.node.size > 0) {
                for (let [key, element] of this.node) {
                    const child = store.getters.getMapValue(element.uuid);
                    this.node.set(key, {...child, value: child.value});
                }
            }
        },
        currentMap() {
            let map = new Map();
            if (this.node && this.node.size > 0) {
                for (let [key, element] of this.node) {
                    const child = store.getters.getMapValue(element.uuid);
                    map.set(key, {...child, value: child.value});
                }
                return map;
            }
            return null;
        },
        getParameter(lvl1, lvl2, index) {
            let parameter = null;
            if (!lvl1) return false;
            if (!lvl2) lvl2 = '';
            if (!isNaN(index)) {
                parameter = this.node.get(lvl1 + '[' + index + '].' + lvl2);
            } else {
                parameter = this.node.get(lvl1 + lvl2);
            }
            return parameter;
        },
        returnChild(childStr) {
            if (typeof childStr == 'string' && this.nodeEntry !== undefined) {
                return helpers.returnChild(this.nodeEntry, childStr);
            } else {
                return null;
            }
        },
        returnParameter(node, key, parameter) {
            return helpers.returnParameter(node, key, parameter);
        },
        returnParameterValue(node, key, parameter, unit) {
            return helpers.returnParameterValue(node, key, parameter, unit);
        },
        timeDialog(time) {
            console.log(time);
        },
        toogleAlarmOverlay(event) {
            this.$refs.alarmOverlay.toggle(event);
        },
        toggle(event) {
            this.$refs.menu.toggle(event);
            // this.this.getModuleValueStatusIOTree();
            // this.getOPCAppTree();
        },
        toggleOP(event, grp) {
            if (grp === 'periods') {
                SocketioService.getRedisNodesParamScaled(`${this.moduleHash}.CFG.periodsSpraying*`, (err, response) => {
                    if (err || !response) {
                        console.log(`[ENATWIDGET] error fetching params: ${err}`);
                    }
                    else {
                        if (Array.isArray(response) && response.length > 0) {
                            let tmpNodes = [];
                            tmpNodes = JSON.parse(JSON.stringify(response));
                            tmpNodes.forEach((entry) => {
                                if (entry.child1) {
                                    const child = JSON.parse(entry.child1)
                                    if (child.index >= 0) {
                                        if (!Array.isArray(this.nodeDataPeriods[child.index])) {
                                            this.nodeDataPeriods[child.index] = [];
                                        }
                                        this.nodeDataPeriods[child.index].push(entry);
                                    }
                                }
                                // console.log(entry);
                            });
                            this.nodeDataPeriodsOld = JSON.parse(JSON.stringify(this.nodeDataPeriods));
                            console.log(this.nodeDataPeriods);
                            console.log(this.nodeDataPeriodsOld);
                            // console.log(this.getDiagDataHzg(0, 'PeriodName'));
                        }
                    }
                }).then(() => {
                    this.showDiagPeriods = true;
                });        
            } else if (grp === 'periodsVents') {
                SocketioService.getRedisNodesParamScaled(`${this.moduleHash}.Perioden_Lueftung*`, (err, response) => {
                    if (err || !response) {
                        console.log(`[ENATWIDGET] error fetching params: ${err}`);
                    }
                    else {
                        if (Array.isArray(response) && response.length > 0) {
                            let tmpNodes = [];
                            tmpNodes = JSON.parse(JSON.stringify(response));
                            tmpNodes.forEach((entry) => {
                                if (entry.child0) {
                                    const child = JSON.parse(entry.child0)
                                    if (child.index >= 0) {
                                        if (!Array.isArray(this.nodeDataLft[child.index])) {
                                            this.nodeDataLft[child.index] = [];
                                        }
                                        this.nodeDataLft[child.index].push(entry);
                                    }
                                }
                                // console.log(entry);
                            });
                            this.nodeDataLftOld = JSON.parse(JSON.stringify(this.nodeDataLft));
                            console.log(this.nodeDataLft);
                            console.log(this.nodeDataLftOld);
                            // console.log(this.getDiagDataHzg(0, 'PeriodName'));
                        }
                    }
                }).then(() => {
                    this.showDiagVents = true;
                }); 
            } else if (grp === 'misc') {
                SocketioService.getRedisNodesParamScaled(`${this.moduleHash}.CFG*`, (err, response) => {
                    if (err || !response) {
                        console.log(`[ENATWIDGET] error fetching params: ${err}`);
                    }
                    else {
                        if (Array.isArray(response) && response.length > 0) {
                            this.nodeDataCfg = JSON.parse(JSON.stringify(response));
                            this.nodeDataCfgOld = JSON.parse(JSON.stringify(response));
                        }
                            console.log(this.nodeDataCfg);
                            console.log(this.nodeDataCfgOld);
                            // console.log(this.getDiagDataHzg(0, 'PeriodName'));
                        }
                }).then(() => {
                    this.showDiagMisc = true;
                }); 
            }
        },
        diagTogglePeriods() {
            if (this.showDiagPeriods) {
                this.showDiagPeriods = false;
            } else {
                this.showDiagPeriods = true;
            }
            this.hideOP();
        },
        diagToggleMisc() {
            if (this.showDiagMisc) {
                this.showDiagMisc = false;
            } else {
                this.showDiagMisc = true;
            }
            this.hideOP();
        },
        hideOP() {
            this.clearVals();
        },
        saveDiag(diag) {
            if (diag) {
                const saveArray = [];
                this.dialogLoading = true;
                this.saveWait = true;

                if (diag === 'periods' && !this.periodCheck) {
                    console.log(this.nodeDataPeriods);
                    console.log(this.nodeDataPeriodsOld);
                    const flatHzg = this.nodeDataPeriods.flatMap(innerArr => innerArr);
                    const flatHzgOld = this.nodeDataPeriodsOld.flatMap(innerArr => innerArr);
                    console.log(flatHzg);
                    console.log(flatHzgOld);

                    flatHzg.forEach((obj1) => {
                    // Find the corresponding object in arr2
                    const obj2 = flatHzgOld.find((item) => item.identifier === obj1.identifier);
                    
                    // If the object is found, compare the values
                    if (obj2) {
                        if (obj1.value !== obj2.value) {
                            const entry = parseNode(obj1, true);
                            saveArray.push(entry);
                        } else if ('plusminus' in obj2 && 'plusminus' in obj1) {
                            if (obj2.plusminus !== obj1.plusminus) {
                                const entry = parseNode(obj1, true);
                                saveArray.push(entry);
                            }
                        }
                    }
                    });
                } else if (diag === 'periodsLft') {
                    console.log(this.nodeDataLft);
                    console.log(this.nodeDataLftOld);
                    const flatHzg = this.nodeDataLft.flatMap(innerArr => innerArr);
                    const flatHzgOld = this.nodeDataLftOld.flatMap(innerArr => innerArr);
                    console.log(flatHzg);
                    console.log(flatHzgOld);

                    flatHzg.forEach((obj1) => {
                    // Find the corresponding object in arr2
                    const obj2 = flatHzgOld.find((item) => item.identifier === obj1.identifier);
                    
                    // If the object is found, compare the values
                    if (obj2) {
                        if (obj1.value !== obj2.value) {
                            const entry = parseNode(obj1, true);
                            saveArray.push(entry);
                        } else if ('plusminus' in obj2 && 'plusminus' in obj1) {
                            if (obj2.plusminus !== obj1.plusminus) {
                                const entry = parseNode(obj1, true);
                                saveArray.push(entry);
                            }
                        }
                    }
                    });
                } else if (diag === 'misc') {
                    console.log(this.nodeDataCfg);
                    console.log(this.nodeDataCfgOld);
                    this.nodeDataCfg.forEach((obj1) => {
                    // Find the corresponding object in arr2
                    const obj2 = this.nodeDataCfgOld.find((item) => item.identifier === obj1.identifier);
                    
                    // If the object is found, compare the values
                    if (obj2) {
                        if (obj1.value !== obj2.value) {
                            const entry = parseNode(obj1, true);
                            saveArray.push(entry);
                        } else if ('plusminus' in obj2 && 'plusminus' in obj1) {
                            if (obj2.plusminus !== obj1.plusminus) {
                                const entry = parseNode(obj1, true);
                                saveArray.push(entry);
                            }
                        }
                    }
                    });
                }
                
                // console.log(saveArray);
                if (saveArray.length > 0) {
                    SocketioService.setOpcValue(saveArray, (err, response) => {
                        if (err) {
                            console.log(err);
                        } else {
                            console.log(response);
                            setOpcValueToast(response, this.$root);
                            this.dialogLoading = false;
                            if (diag === 'periods') this.showDiagPeriods = false;
                            if (diag === 'misc') this.showDiagMisc = false;
                            this.hideOP();
                        }
                    });
                } else {
                    if (diag === 'periods' && !this.periodCheck) {
                        this.saveDiagNoChanges();
                        this.showDiagPeriods = false;
                    } else if (diag === 'periods' && this.periodCheck) {
                        this.saveDiagNoPeriods();
                    } else if (diag === 'misc') {
                        this.saveDiagNoChanges();
                        this.showDiagMisc = false;
                    }
                }
            }
        },
        saveDiagNoChanges() {
            setOpcValueToast(null, this.$root);
            this.dialogLoading = false;
            this.hideOP();
        },
        saveDiagNoPeriods() {
            this.$toast.add({
                severity: 'warn',
                summary: 'Eine Periode aktivieren!',
                detail: 'Es muss zumindest eine Periode aktiviert werden.',
                life: 3000,
            });
            this.dialogLoading = false;
            this.saveWait = false;
        },
        toogleNameDiag(name) {
            this.changeDiagName = JSON.parse(JSON.stringify(name));
            this.showDiagName = true;
        },
        hideNameDiag() {
            this.changeDiagName = null;
            this.showDiagName = false;
        },
        saveNameDiag(node) {
            console.log(node);
            if (node.child1) {
                const child = JSON.parse(node.child1);
                if (child.index >= 0) {
                    console.log(child.index)
                    let obj2 = null;
                    obj2 = this.nodeDataPeriods[child.index].find((item) => item.identifier === node.identifier);
                    if (obj2) {
                        if (typeof this.changeDiagName.value === 'string' && this.changeDiagName.value.length > 0) {
                            obj2.value = this.changeDiagName.value;
                        }
                        console.log(obj2);
                    }
                }
            }
            this.hideNameDiag();
        },
        focusElement(event) {
            if (event !== undefined) {
                event.target.select();
            }
        },
        hideDialog() {
            this.entryDialog = false;
            this.submitted = false;
            // clearInterval(this.intval_Value);
            // this.getUserList();
        },
        resetDialogData() {
            this.ioDialogData = {
                style: {},
                components: {},
                data: {},
            };
            this.selectedKey = {};
            this.expandedKeys = {};
        },
        changeVal(ioData) {
            console.log(ioData);
            this.$emit('entrySave', ioData);
        },
        editEntry(ioData) {
            this.ioDialogData = JSON.parse(JSON.stringify(ioData));
            this.ioObj = JSON.parse(JSON.stringify(this.moduleData));
            this.entryDialog = true;
        },
        saveDialog() {
            this.submitted = true;
            if (this.ioDialogData.i) {
                this.$emit('entrySave', this.ioDialogData);
                this.entryDialog = false;
                this.resetDialogData();
            }
        },
        duplicateEntry() {
            this.$emit('entryDuplicate', this.content);
            this.resetDialogData();
        },
        deleteEntry() {
            this.$emit('entryDelete', this.content);
            this.resetDialogData();
        },
        expandNode(node) {
            this.expandedKeys[node.key] = true;
            if (node.children && node.children.length) {
                for (let child of node.children) {
                    this.expandNode(child);
                    if (child.key === this.ioObj._id) {
                        this.selectedKey[this.ioObj._id] = true;
                    }
                }
            }
        },
        onNodeSelect(node) {
            const newObj = store.getters.getValue(node.key);
            this.selectedKey = node.key;
            this.ioDialogData.data.id = node.key;

            this.$toast.add({
                severity: 'success',
                summary: 'Node ausgewählt',
                detail: newObj.metadata.name + '\n' + this.selectedKey + '\n' + newObj.nodeId,
                life: 3000,
            });
        },
        editValve(ioData) {
            console.log(ioData);
        },
        hideValveDialog() {
            this.valveDialog = false;
            this.submitted = false;
            // clearInterval(this.intval_Value);
            // this.getUserList();
        },
        focusDiag(event) {
            this.periodDiagDelay(event);
        },
        periodDiagDelay(event) {
            setTimeout(() => {
                this.diagPeriod = this.periodIndex;
                this.focusElement(event);
            }, 300);
        },
        showChart(value) {
        if (value === 'value1') this.showChart1 = true;
        },
    },
});
</script>

<style lang="scss" scoped>
.bounce-enter-active {
    animation: bounce-in 0.5s;
    // transition: opacity .5s;
}

.bounce-leave-active {
    animation: bounce-out 0.5s;
    // transition: opacity .5s;
}

.slide-fade-enter-active {
    transition: all 0.5s ease;
}

.slide-fade-leave-active {
    transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter,
.slide-fade-leave-to

/* .slide-fade-leave-active for <2.1.8 */
    {
    transform: translateX(10px);
    opacity: 0;
}

.pulse-enter-active {
    animation: pulse 1s;
}

.pulse-leave-active {
    animation: pulse 0.5s;
}

.pulse-loop {
    animation: pulse 1s;
    animation-iteration-count: infinite;
}

.fade-in-enter-active {
    animation: fadeIn linear 2s;
}

.fade-in-leave-active {
    animation: fadeOut linear 2s;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes fadeInOut {
    0% {
        // opacity: 0;
    }
    50% {
        // opacity: 1;
        background-color: rgba(117, 18, 0, 0.5);
    }
    100% {
        // opacity: 0;
    }
}

@keyframes pulse {
    0% {
        // transform: scale(1); /* scaling to 0 */
        opacity: 0.8;
        // color: yellowgreen;
        // transform-origin: center center;
        // transform: scaleY(.9);
        text-shadow: black 2px 0 10px;
        transform: scale(0.9);
        /* increasing the size */
    }

    30% {
        transform: scale(1.1);
        /* increasing the size */
        text-shadow: gray 1px 0 10px;
    }

    100% {
        transform: scale(1);
        /* seeting back to initial size */
        opacity: 1;
    }
}

.alarm {
    animation: fadeInOut 1s infinite;
    -webkit-animation: fadeInOut 1s infinite;
}

.grid {
    margin: 0 -0.5em 0 -0.5em !important;
}

.col {
    // line-height: 1.4em !important;
    margin: 0 !important;
    padding: 0 !important;
}

.p-divider {
    margin: 0 !important;
    padding: 0 !important;
}

.box {
    overflow: hidden;
    // text-shadow: 1px 1px 1px black;
}

.card {
    font-family: 'SensorFont';
    line-height: 1.3rem;
    position: relative;
}

.text-normal {
    line-height: 0;
}
// .list-enter-active,
// .list-leave-active {
//   transition: all 0.5s ease;
//   transform: scale(0.7); /* increasing the size */
// }
// .list-enter-from,
// .list-leave-to {
//   opacity: 0;
//   transform: scale(1); /* increasing the size */
// }
.list-enter-active {
    animation: list 1s;
}

.list-leave-active {
    animation: list 0.5s;
}

@keyframes list {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
.p-togglebutton {
    font-size: 0.8rem;
    line-height: 1rem;
    padding: 0.2rem;
    margin: 0;
}
</style>